import React from "react";

import { Range, getTrackBackground } from "react-range";
import { IRenderTrackParams } from "react-range/lib/types";

// The algorithm actually caps it at 10, but we don't have anything that comes to more than
// 6 in our database at the moment.
export const maxDifficulty = 8;

export interface Props {
  value: { min: number; max: number };
  onChange: (newValue: { min: number; max: number }) => void;
}

export const DifficultyPicker = ({ value, onChange }: Props) => {
  const filterDifficulty = [value.min, value.max];

  return (
    <div style={{ paddingLeft: "24px", paddingRight: "24px" }}>
      <Range
        values={filterDifficulty}
        min={1}
        max={maxDifficulty}
        step={1}
        onChange={(values) => onChange({ min: values[0], max: values[1] })}
        renderTrack={({ props, children }: IRenderTrackParams) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values: filterDifficulty,
                  colors: ["#ccc", "#04868f", "#ccc"],
                  min: 0,
                  max: maxDifficulty,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "42px",
              width: "42px",
              borderRadius: "4px",
              backgroundColor: "#FFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 6px #AAA",
            }}
          >
            <div
              style={{
                height: "16px",
                width: "5px",
                backgroundColor: isDragged ? "#548BF4" : "#CCC",
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

export default DifficultyPicker;

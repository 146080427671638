import React from "react";
import { Range, getTrackBackground } from "react-range";
import { IRenderTrackParams } from "react-range/lib/types";

export const maxDuration = 60 * 60;

export interface Props {
  value: { min: number; max: number };
  onChange: (newValue: { min: number; max: number }) => void;
}

const formatDuration = (durationSeconds: number): string => {
  const minutes = Math.floor(durationSeconds / 60);
  return `${minutes} min`;
};

export const DurationPicker = ({ value, onChange }: Props) => {
  const filterDuration = [value.min, value.max];

  return (
    <div style={{ paddingLeft: "24px", paddingRight: "24px" }}>
      <Range
        values={filterDuration}
        min={0}
        max={maxDuration}
        step={60}
        onChange={(values) => onChange({ min: values[0], max: values[1] })}
        renderTrack={({ props, children }: IRenderTrackParams) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values: filterDuration,
                  colors: ["#ccc", "#04868f", "#ccc"],
                  min: 0,
                  max: maxDuration,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ index, props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "42px",
              width: "42px",
              borderRadius: "4px",
              backgroundColor: "#FFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 6px #AAA",
            }}
          >
            {isDragged && (
              <div
                style={{
                  position: "absolute",
                  top: "-28px",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "14px",
                  fontFamily: "Arial,Helvetica Neue,Helvetica,sans-serif",
                  padding: "4px",
                  borderRadius: "4px",
                  backgroundColor: "#548BF4",
                }}
              >
                {formatDuration(filterDuration[index])}
              </div>
            )}
            <div
              style={{
                height: "16px",
                width: "5px",
                backgroundColor: isDragged ? "#548BF4" : "#CCC",
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

export default DurationPicker;

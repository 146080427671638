exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-markdown-remark-fields-slug-tsx": () => import("./../../../src/pages/blog/{MarkdownRemark.fields__slug}.tsx" /* webpackChunkName: "component---src-pages-blog-markdown-remark-fields-slug-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-topics-business-tsx": () => import("./../../../src/pages/topics/business.tsx" /* webpackChunkName: "component---src-pages-topics-business-tsx" */),
  "component---src-pages-topics-education-tsx": () => import("./../../../src/pages/topics/education.tsx" /* webpackChunkName: "component---src-pages-topics-education-tsx" */),
  "component---src-pages-topics-food-tsx": () => import("./../../../src/pages/topics/food.tsx" /* webpackChunkName: "component---src-pages-topics-food-tsx" */),
  "component---src-pages-topics-idioms-tsx": () => import("./../../../src/pages/topics/idioms.tsx" /* webpackChunkName: "component---src-pages-topics-idioms-tsx" */),
  "component---src-pages-topics-language-learning-tsx": () => import("./../../../src/pages/topics/language-learning.tsx" /* webpackChunkName: "component---src-pages-topics-language-learning-tsx" */),
  "component---src-pages-topics-politics-tsx": () => import("./../../../src/pages/topics/politics.tsx" /* webpackChunkName: "component---src-pages-topics-politics-tsx" */),
  "component---src-pages-topics-sports-tsx": () => import("./../../../src/pages/topics/sports.tsx" /* webpackChunkName: "component---src-pages-topics-sports-tsx" */),
  "component---src-pages-topics-technology-tsx": () => import("./../../../src/pages/topics/technology.tsx" /* webpackChunkName: "component---src-pages-topics-technology-tsx" */),
  "component---src-pages-topics-travel-tsx": () => import("./../../../src/pages/topics/travel.tsx" /* webpackChunkName: "component---src-pages-topics-travel-tsx" */),
  "component---src-pages-video-videos-yaml-slug-tsx": () => import("./../../../src/pages/video/{VideosYaml.slug}.tsx" /* webpackChunkName: "component---src-pages-video-videos-yaml-slug-tsx" */)
}


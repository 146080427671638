import { maxDuration } from "./components/DurationPicker";
import { maxDifficulty } from "./components/DifficultyPicker";
import { VideoFilter } from "./components/VideoFilterOptions";

// This will be false during Gatsby server-side rendering.
export const isBrowser = typeof window !== "undefined";

export const defaultVideoFilter: VideoFilter = {
  topics: [],
  duration: { min: 0, max: maxDuration },
  difficulty: { min: 1, max: maxDifficulty },
  subtitles: {
    english: "either",
    chinese: "either",
    pinyin: "either",
  },
};

// This stores the current filters in a context object that
// can be maintained across page navigations.

import React, { useState } from "react";
import { VideoFilter } from "../components/VideoFilterOptions";
import { defaultVideoFilter } from "../utils";

export interface IVideoBrowseContext {
  filter: VideoFilter;
  setFilter: (newFilter: VideoFilter) => void;
}

export const VideoBrowseContext = React.createContext<IVideoBrowseContext>({
  filter: defaultVideoFilter,
  setFilter: () => null,
});

export const VideoBrowseContextProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [filter, setFilter] = useState<VideoFilter>(defaultVideoFilter);

  return (
    <VideoBrowseContext.Provider value={{ filter, setFilter }}>
      {children}
    </VideoBrowseContext.Provider>
  );
};
